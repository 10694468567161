jQuery(document).ready(function($){

    if($('.interactive_map').length > 0){
        /*$('.interactive_map .marker').hover(function(){
            var marker = $(this);
            marker.parent().find('.pop_up').height(marker.parent().height() + 20).empty().removeClass('show_pop').append('<img src="'+marker.attr('data-image')+'" />');
            setTimeout(function(){
                marker.parent().find('.pop_up').addClass('show_pop');
            },600)
        },function(){
            var marker = $(this);
            marker.parent().find('.pop_up').removeClass('show_pop');
            setTimeout(function(){
                marker.parent().find('.pop_up').empty();
            },600)
        });*/

        $('.interactive_map .marker').mouseenter(function(){
            var marker = $(this);
            if(!marker.parent().find('.pop_up').hasClass('show_pop')){
                marker.parent().find('.pop_up').height(marker.parent().height() + 20).empty().removeClass('show_pop').append('<img src="'+marker.attr('data-image')+'" />');
                setTimeout(function(){
                    marker.parent().find('.pop_up').addClass('show_pop');
                },0)
            }
        });
        $('.interactive_map .marker').mouseleave(function(){
                var marker = $(this);
                marker.parent().find('.pop_up').removeClass('show_pop');
                setTimeout(function(){
                    marker.parent().find('.pop_up').empty();
                },0)
            }
        ).mouseleave();

    }

});